import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Contact = () => {
	return (
		<Layout>
			<Seo />
			<iframe
				src='https://docs.google.com/forms/d/e/1FAIpQLSehnYGCDgPi5fKx8s6_02wD1Ro4KxTk3jHAgGWl4OIGerkwxA/viewform?embedded=true'
				width='100%'
				height='1000px'
				frameborder='0'
				marginheight='0'
				marginwidth='0'>
				読み込んでいます…
			</iframe>
		</Layout>
	)
}

export default Contact
